const avisoLegalText = `
<h2>Aviso Legal</h2>

<b>Datos identificativos</b>
<p>Estás visitando la página web www.vidfestival.es titularidad de En un tardeo de la Mancha S.L. con domicilio social en Calle San Roque, 46 - 1B, 13250, Daimiel, Ciudad Real, España, con CIF nº B09602996</p>
<p>(que en este documento se llama ¨el Titular¨).</p>
<p>Puedes contactar con EL TITULAR a través de la dirección de correo electrónico info@vidfestival.es</p>

<b>Usuarios</b>
<p>Estas condiciones (en adelante Aviso Legal) tienen por finalidad regular el uso de la página web de EL TITULAR que pone a disposición del público.</p>
<p>El acceso y/o uso de esta página web de EL TITULAR atribuye la condición de USUARIO, que acepta, desde dicho acceso y/o uso, las condiciones generales de uso aquí reflejadas. Estas condiciones serán de aplicación independientemente de las condiciones generales de contratación que en su caso resulten de obligado cumplimiento.</p>

<b>Uso del portal</b>
<p>www.vidfestival.es proporciona el acceso a multitud de informaciones, servicios, programas o datos (en adelante, «los contenidos») en Internet pertenecientes a EL TITULAR o a sus licenciantes a los que el USUARIO pueda tener acceso.</p>
<p>El USUARIO asume la responsabilidad del uso del portal. Dicha responsabilidad se extiende al registro que fuese necesario para acceder a determinados servicios o contenidos. En dicho registro el USUARIO será responsable de aportar información real y lícita. Como consecuencia de este registro, al USUARIO se le puede entregar una contraseña de la que será responsable, comprometiéndose a hacer un uso diligente y confidencial de la misma.</p>
<p>El USUARIO se compromete a hacer un uso adecuado de los contenidos y servicios que EL TITULAR ofrece a través de su portal y (con carácter enunciativo, pero no limitativo), a no usarlos para:</p>
<p>· Realizar actividades ilícitas, ilegales o contrarias a la buena fe y al orden público</p>
<p>· Difundir contenidos o propaganda de carácter racista, xenófobo, pornográfico-ilegal, de apología del terrorismo o atentatorio contra los derechos humanos</p>
<p>· Provocar daños en los sistemas físicos y lógicos de El TITULAR, de sus proveedores o de terceras personas, introducir o difundir en la red virus informáticos o cualesquiera otros sistemas físicos o lógicos que sean susceptibles de provocar los daños anteriormente mencionados</p>
<p>· Intentar acceder y, en su caso, utilizar las cuentas de correo electrónico de otros usuarios y modificar o manipular sus mensajes</p>
<p>· Utilizar el sitio web ni las informaciones que en él se contienen con fines comerciales, políticos, publicitarios y para cualquier uso comercial, sobre todo el envío de correos electrónicos no solicitados.</p>

<b>Protección de datos</b>
<p>Todo lo relativo a la política de protección de datos se encuentra recogido en el documento de política de privacidad.</p>

<b>Contenidos. Propiedad intelectual e industrial</b>
<p>EL TITULAR es propietario de todos los derechos de propiedad intelectual e industrial de su página web, así como de los elementos contenidos en la misma (a título enunciativo: imágenes, fotografías, sonido, audio, vídeo, software o textos; marcas o logotipos, combinaciones de colores, estructura y diseño, selección de materiales usados, programas de ordenador necesarios para su funcionamiento, acceso y uso, etc.), titularidad del TITULAR o de sus licenciantes.</p>
<p>Todos los derechos reservados. De acuerdo con lo establecido en los artículos 8 y 32.1, párrafo segundo, de la Ley de Propiedad Intelectual, quedan expresamente prohibidas la reproducción, la distribución y la comunicación pública, incluida su modalidad de puesta a disposición, de la totalidad o parte de los contenidos de esta página web, con fines comerciales, en cualquier soporte y por cualquier medio técnico, sin la autorización del TITULAR.</p>
<p>El USUARIO se compromete a respetar los derechos de Propiedad Intelectual e Industrial titularidad del TITULAR. Podrá visualizar los elementos del portal e incluso imprimirlos, copiarlos y almacenarlos en el disco duro de su ordenador o en cualquier otro soporte físico siempre y cuando sea, única y exclusivamente, para su uso personal y privado. El USUARIO no podrá suprimir, alterar, eludir o manipular cualquier dispositivo de protección o sistema de seguridad que estuviera instalado en las páginas del TITULAR.</p>

<b>Exclusión de garantías y responsabilidad</b>
<p>El USUARIO reconoce que la utilización de la página web y de sus contenidos y servicios se desarrolla bajo su única responsabilidad. En concreto, a título meramente enunciativo, EL TITULAR no asume ninguna responsabilidad en los siguientes ámbitos:</p>
<p>a) La disponibilidad en el funcionamiento de la página web, sus servicios y contenidos y su calidad o interoperabilidad.</p>
<p>b) La finalidad para la que la página web sirva a los objetivos del USUARIO.</p>
<p>c) La infracción de la legislación vigente por parte del USUARIO o terceros y, en concreto, de los derechos de propiedad intelectual o industrial que sean propiedad de otras personas o entidades.</p>
<p>d) La existencia de códigos maliciosos o cualquier otro elemento informático dañino que pudiera causar daños al sistema informático del USUARIO o de terceros. Es responsabilidad del USUARIO, en todo caso, disponer de herramientas adecuadas para la detección y desinfección de estos elementos.</p>
<p>e) El acceso fraudulento a los contenidos o servicios por terceros no autorizados, o, en su caso, la captura, eliminación, alteración, modificación o manipulación de los mensajes y comunicaciones de cualquier clase que dichos terceros pudieran realizar.</p>
<p>f) La exactitud, veracidad, actualidad y utilidad de los contenidos y servicios ofrecidos y la utilización posterior que de ellos haga el USUARIO. EL TITULAR pondrá todos los esfuerzos y medios razonables para facilitar la información actualizada y fehaciente.</p>
<p>g) Los daños producidos a equipos informáticos durante el acceso a la página web y los daños producidos a los USUARIOS cuando tengan su origen en fallos o desconexiones en las redes de telecomunicaciones que interrumpan el servicio.</p>
<p>h) Los daños o perjuicios que se deriven de circunstancias ocurridas por caso fortuito o fuerza mayor</p>
<p>En caso de que existan foros, en el uso de los mismos u otros espacios análogos, ha de tenerse en cuenta que, los mensajes reflejan únicamente la opinión del USUARIO que los remite, que es el único responsable. EL TITULAR no se hace responsable del contenido de los mensajes enviados por el USUARIO.</p>

<b>Modificación de este aviso legal y duración</b>
<p>EL TITULAR se reserva el derecho de realizar sin previo aviso las modificaciones que considere oportunas en su portal, pudiendo cambiar, suprimir o añadir tanto los contenidos y servicios que se presten a través de la misma como la forma en la que éstos aparezcan presentados o localizados en su portal.</p>
<p>La vigencia de las citadas condiciones irá en función de su exposición y estarán vigentes hasta que sean modificadas por otras debidamente publicadas.</p>

<b>Enlaces</b>
<p>En el caso de que en www.vidfestival.es se incluyesen enlaces o hipervínculos a otros sitios de Internet, EL TITULAR no ejercerá ningún tipo de control sobre dichos sitios y contenidos. En ningún caso EL TITULAR asumirá responsabilidad alguna por los contenidos de algún enlace perteneciente a un sitio web ajeno, ni garantizará la disponibilidad técnica, calidad, fiabilidad, exactitud, amplitud, veracidad, validez y constitucionalidad de cualquier material o información contenido en ninguno de dichos hipervínculos u otros sitios de Internet. Igualmente, la inclusión de estas conexiones externas no implicará ningún tipo de asociación, fusión o participación con las entidades conectadas.</p>

<b>Derecho de exclusión</b>
<p>EL TITULAR se reserva el derecho a denegar o retirar el acceso a portal y/o los servicios ofrecidos sin necesidad de advertencia previa, a instancia propia o de un tercero, a aquellos usuarios que incumplan el contenido de este aviso legal.</p>

<b>Generalidades</b>
<p>EL TITULAR perseguirá el incumplimiento de estas condiciones, así como cualquier utilización indebida de su portal ejerciendo todas las acciones civiles y penales que le puedan corresponder en derecho.</p>

<b>Legislación aplicable y jurisdicción</b>
<p>La relación entre EL TITULAR y el USUARIO se regirá por la normativa española vigente. Todas las disputas y reclamaciones derivadas de este aviso legal se resolverán por los juzgados y tribunales competentes.</p>
<p>`;

export default avisoLegalText;